import React from 'react';
import BlogCard from '../Cards/BlogCard';
import { StaticQuery, graphql } from 'gatsby';
import dateFormat from 'dateformat';

const postsGraphql = graphql`
  query LatestBlogQuery {
    blog: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
      skip: 0
    ) {
      totalCount
      posts: nodes {
        timeToRead
        excerpt
        fields {
          slug
        }
        frontmatter {
          author
          date
          title
          thumbnail
        }
      }
    }
  }
`;

export default function LatestPostSection() {
  const currentYear = new Date().getFullYear().toString();

  return (
    <section className="pb-2">
      <h2 className="font-bold">LATEST POSTS</h2>
      <div>
        <StaticQuery
          query={postsGraphql}
          render={(data) => {
            return data.blog.posts.map((post, i) => {
              const year = dateFormat(post.frontmatter.date, 'yyyy');
              const addionalYear = year !== currentYear;

              return (
                <BlogCard
                  key={i}
                  className="py-4"
                  title={post.frontmatter.title}
                  slug={post.fields.slug}
                  date={
                    `${dateFormat(post.frontmatter.date, 'mmm d')} ${addionalYear ? `, ${year}` : ''}`
                  }
                  thumbnail={post.frontmatter.thumbnail}
                  excerpt={post.excerpt}
                  timeToRead={post.timeToRead}
                />
              );
            });
          }}
        />
      </div>
    </section>
  );
}