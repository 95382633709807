import * as React from "react";

import SocialMediaCard from "../components/Cards/SocialMediaCard";
import MainTemplate from "../components/Template/MainTemplate";
import PortfolioSection from "../components/Sections/PortfolioSection";
import LatestPostSection from "../components/Sections/LatestPostSection";
import ToolsAndSkillsSection from "../components/Sections/ToolsAndSkillsSection";
import HeroSection from "../components/Sections/HeroSection";
import AboutMeSection from "../components/Sections/AboutMeSection";

const IndexPage = () => {
  return (
    <MainTemplate>
      <HeroSection />
      <div className="flex">
        <div
          className="sidebar pt-10 sticky top-0 hidden md:block"
          style={{
            minWidth: "200px",
            maxWidth: "320px",
          }}
        >
          <SocialMediaCard className="px-6" />
        </div>
        <div className="content">
          <AboutMeSection />
          <PortfolioSection />
          <LatestPostSection />
          <ToolsAndSkillsSection />
        </div>
      </div>
    </MainTemplate>
  );
};

export default IndexPage;
