import React from "react";
import { Icon } from "@iconify/react";
import githubFilled from "@iconify/icons-ant-design/github-filled";
import linkedinFilled from "@iconify/icons-ant-design/linkedin-filled";
import instagramFilled from "@iconify/icons-ant-design/instagram-filled";
import dribbbleCircleFilled from "@iconify/icons-ant-design/dribbble-circle-filled";
import emailFilled from "@iconify/icons-ant-design/mail-filled";
import mediumFilled from "@iconify/icons-ant-design/medium-square-filled";
import twitterSquareFilled from "@iconify/icons-ant-design/twitter-square-filled";

export default function SocialMediaCard(props) {
  const socialMedia = [
    {
      name: "Email",
      icon: emailFilled,
      url: "emailto:dickaismaji@gmail.com",
    },
    {
      name: "Github",
      icon: githubFilled,
      url: "https://github.com/dicka88",
    },
    {
      name: "Medium",
      icon: mediumFilled,
      url: "https://dickaismaji.medium.com",
    },
    {
      name: "Linkedin",
      icon: linkedinFilled,
      url: "https://linkedin.com/in/dickaismaji",
    },
    {
      name: "Instagram",
      icon: instagramFilled,
      url: "https://instagram.com/dickaismaji",
    },
    {
      name: "Twitter",
      icon: twitterSquareFilled,
      url: "https://twitter.com/dickaismaji",
    },
    {
      name: "Dribbble",
      icon: dribbbleCircleFilled,
      url: "https://dribbble.com/dickaismaji",
    },
  ];

  return (
    <div {...props}>
      <ul>
        {socialMedia.map(({ name, icon, url }, index) => (
          <li key={index} className="mb-2">
            <a href={url} target="blank">
              <Icon className="inline mr-4" icon={icon} /> {name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
