import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const ThumbnailImage = styled.img`
  min-width: 200px;
  max-width: 100%;
  min-height: 150px;
  max-height: 200px !important;
  user-select: none;
  height: 100%;
  object-fit: cover;
`;

export default function BlogCard({
  title,
  slug,
  thumbnail,
  date,
  excerpt,
  timeToRead,
  isFlex,
  justifyEnd,
  className
}) {
  return (
    <article className={className}>
      <Link to={`/blog${slug}`}>
        <div className={`md:flex ${justifyEnd ? 'justify-end' : ''} ${isFlex ? 'flex-wrap' : ''}`}>
          <div className="flex justify-between">
            <small className="md:hidden mb-4 md:mb-0 font-thin">{date}</small>
            <small className="font-thin md:hidden">{timeToRead} min read</small>
          </div>
          <div className={`${isFlex ? '' : 'md:mr-4'} w-100`}>
            <ThumbnailImage className="w-full mb-4 md:mb-0 md:w-[200px] lazyload" data-src={thumbnail} alt={title} />
          </div>
          <div className='block w-100'>
            <small className="hidden md:block font-thin">{date}</small>
            <h3 className='py-0'>{title}</h3>
            <p className="mb-2 text-base">{excerpt}</p>
            <small className="font-thin hidden md:block">{timeToRead} min read</small>
          </div>
        </div>
      </Link>
    </article >
  );
}