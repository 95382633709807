import React from 'react';
import { Fade } from "react-reveal";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import icon from '../../images/icon.png';
import gradientAmbientBackground from '../../images/bg/main_background.png';
import PrimaryButton from "../../components/Buttons/PrimaryButton";

const photoProfileStyle = {
  width: '130px',
  height: '130px'
};

const iconTechStyle = {
  marginRight: '0.5em',
  marginBottom: '0.5em'
};

export default function HeroSection() {
  return (
    <div className="relative overflow-hidden rounded-[2rem] p-8 mb-8 w-full h-full flex flex-col md:flex-row" style={{
      backgroundImage: `url(${gradientAmbientBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}>
      <div className="md:mr-8 mb-8">
        <img className="mx-auto " style={photoProfileStyle} src={icon} alt="Dicka Ismaji" />
      </div>
      <div className="text-center md:text-left">
        <h1 className="font-bold text-3xl md:text-5xl pt-0">
          <Fade bottom cascade>
            DICKA ISMAJI
          </Fade>
        </h1>
        <span className="block">Fullstack Web Developer</span>
        <small>React, Next js, Node Js, Laravel</small>
        <div className="mt-6">
          <Link to="/contact">
            <PrimaryButton>
              Hire me
            </PrimaryButton>
          </Link>
        </div>
      </div>
      <div className="absolute hidden lg:block right-8 bottom-8">
        <div className="flex">
          <StaticImage placeholder="blurred" imgStyle={{ objectFit: 'contain' }} height={30} style={iconTechStyle} src={'../../images/tech/js.png'} alt="Javascript" />
          <StaticImage placeholder="blurred" imgStyle={{ objectFit: 'contain' }} height={30} style={iconTechStyle} src={'../../images/tech/node.png'} alt="Node" />
          <StaticImage placeholder="blurred" imgStyle={{ objectFit: 'contain' }} height={30} style={iconTechStyle} src={'../../images/tech/laravel.png'} alt="Laravel" />
          <StaticImage placeholder="blurred" imgStyle={{ objectFit: 'contain' }} height={30} style={iconTechStyle} src={'../../images/tech/figma.png'} alt="Figma" />
        </div>
        <div className="flex">
          <StaticImage placeholder="blurred" imgStyle={{ objectFit: 'contain' }} height={30} style={iconTechStyle} src={'../../images/tech/pg.png'} alt="Postgres" />
          <StaticImage placeholder="blurred" imgStyle={{ objectFit: 'contain' }} height={30} style={iconTechStyle} src={'../../images/tech/react.png'} alt="React" />
          <StaticImage placeholder="blurred" imgStyle={{ objectFit: 'contain' }} height={30} style={iconTechStyle} src={'../../images/tech/ci.png'} alt="Codeigniter" />
          <StaticImage placeholder="blurred" imgStyle={{ objectFit: 'contain' }} height={30} style={iconTechStyle} src={'../../images/tech/phpmysql.png'} alt="PHP Mysql" />
        </div>
      </div>
    </div>
  );
}
