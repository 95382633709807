import React from "react";

export default function AboutMeSection() {
  return (
    <section className="pb-2">
      <h2 className="font-bold">ABOUT ME</h2>
      <p>
        Frontend and Backend enthusiast such Node JS and Frontend React & Next JS,
        I also have strong fundamentals of Backends such as PHP
        Laravel and Codeigniter and other databases like RDBMS MySQL and
        PostgreSQL. I like to exploring new things about programming and design.
      </p>
    </section>
  );
}
