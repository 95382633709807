import React from 'react';
import { Fade } from 'react-reveal';
import { StaticImage } from 'gatsby-plugin-image';

export default function ToolsAndSkillsSection() {
  return (
    <section>
      <h1>Skills and Tools</h1>
      <div>
        <p>Technology and tools that is be my favorite</p>
        <div className="grid gap-4 grid-cols-4 md:grid-cols-6 ">
          <Fade bottom>
            <StaticImage className="hover:scale-105 transition-all duration-75" src="../../../static/images/tools/js.png" placeholder="blurred" alt="Javascript" />
          </Fade>
          <Fade bottom delay={50}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/php.png"} placeholder="blurred" alt="PHP" />
          </Fade>
          <Fade bottom delay={100}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/laravel.png"} placeholder="blurred" alt="Laravel" />
          </Fade>
          <Fade bottom delay={150}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/codeigniter.png"} placeholder="blurred" alt="Codeigniter" />
          </Fade>
          <Fade bottom delay={200}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/express.png"} placeholder="blurred" alt="Express" />
          </Fade>
          <Fade bottom delay={250}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/react.png"} placeholder="blurred" alt="React" />
          </Fade>
          <Fade bottom delay={500}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/nextjs.png"} placeholder="blurred" alt="Next JS" />
          </Fade>
          <Fade bottom delay={500}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/gatsbyjs.png"} placeholder="blurred" alt="Gatsby JS" />
          </Fade>
          <Fade bottom delay={500}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/mysql.png"} placeholder="blurred" alt="MySQL" />
          </Fade>
          <Fade bottom delay={500}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/mongodb.png"} placeholder="blurred" alt="MongoDB" />
          </Fade>
          <Fade bottom delay={500}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/postgresql.png"} placeholder="blurred" alt="PostgreSQL" />
          </Fade>
          <Fade bottom delay={500}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/redux.png"} placeholder="blurred" alt="Redux" />
          </Fade>
          <Fade bottom delay={1000}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/nodejs.png"} placeholder="blurred" alt="Node JS" />
          </Fade>
          <Fade bottom delay={1000}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/linux.png"} placeholder="blurred" alt="Linux" />
          </Fade>
          <Fade bottom delay={1000}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/tailwindcss.png"} placeholder="blurred" alt="Tailwind CSS" />
          </Fade>
          <Fade bottom delay={1000}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/bootstrapcss.png"} placeholder="blurred" alt="Bootstrap CSS" />
          </Fade>
          <Fade bottom delay={1000}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/vscode.png"} placeholder="blurred" alt="Visual Studio Code" />
          </Fade>
          <Fade bottom delay={1000}>
            <StaticImage className="hover:scale-105 transition-all duration-75" src={"../../../static/images/tools/figma.png"} placeholder="blurred" alt="Figma" />
          </Fade>
        </div>
      </div>
    </section>
  );
}
